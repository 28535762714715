import { get } from "#shared/components/api/http";
import env from "#src/env";
import { UsersEngagements } from "../../types/db";

export default async function fetchUsersEngagement(
  fingerprintId: string,
  briefRef?: string
): Promise<UsersEngagements[] | void> {
  let url = `${env.VITE_API_BASE_URL}/community/users-engagements/${fingerprintId}`;
  if (briefRef) {
    url = url + `?briefRef=${briefRef}`;
  }
  try {
    return await get<UsersEngagements[]>(url);
  } catch (e) {
    console.log("Error when fetching user engagement", e);
  }
}
